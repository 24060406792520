import { GACategory } from 'client/tracker';
import ButtonLine from 'app/components/buttons/button-line';
import CustomerFeedback from 'app/modules/landings/customer-feedback';
import FAQ from 'app/modules/landings/faq';
import LogoLine from 'app/components/misc/logo-line';
import Navbar from 'app/components/misc/navbar';
import Search from 'app/components/search';
import Subscribe from 'app/modules/landings/subscribe';
import WhyPipelinepharma from 'app/modules/landings/why-pipelinepharma';
import React from 'react';
import FooterAdvertisement from 'app/components/footer-advertisement/footer-advertisement';
import NoIndexMetaData from 'client/noindex-meta-description';

const HomeAdvertisement: React.FC = () => (
  <div>
    <NoIndexMetaData />
    <Navbar />
    <Search />
    <WhyPipelinepharma />
    <CustomerFeedback />
    <LogoLine />
    <FAQ />
    <Subscribe />
    <ButtonLine category={GACategory ? GACategory.Landing : undefined} />
    <FooterAdvertisement />
  </div>
);

export default HomeAdvertisement;
