import { _, media } from 'app/styles/breakpoint';
import classNames from 'app/utils/class-names';
import React from 'react';
import styled from 'styled-components';

const CustomerFeedbackItemContainer = styled.div`
  flex: 1;
  min-width: 330px;
  max-width: 100%;
  width: 570px;
  border-radius: 3px;

  box-shadow: 0 4px 28px 0 rgba(53, 64, 82, 0.1);
  margin: 10px;
  padding: 60px;
  padding-right: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${media.max.md`
  padding: 40px;
  width: 100%;
  min-width: 330px;
  `}
  ${media.max.sm`
    padding-left: 34px;
    padding-right: 34px;
    margin-top: 10px;
    padding-top:35px;
    min-width: 350px;
  `}
  ${media.max.xs`
    padding: 22px;
    min-width: 220px;
  `}
`;

const Quote = styled.img`
  width: 57.8px;
  height: 50.6px;
  object-fit: contain;
  margin-bottom: 13px;
`;

const Comment = styled.div`
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const PersonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 39px;
  min-height: 132px;
  ${_(media.max.tablet)} {
    min-height: 190px;
  }
  ${_(media.max.sm)} {
    margin-top: 15px;
    min-height: fit-content;
    flex-direction: column;
  }
`;
const PersonInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;

  &.with-image {
    width: 200px;
    margin: 25px;
  }

  ${_(media.max.sm)} {
    &.with-image {
      margin: 25px 0;
    }
  }
`;

const PersonImage = styled.img`
  width: 90px;
  height: 90px;
  object-fit: contain;
`;

const PersonName = styled.div`
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.22;
  letter-spacing: 0.5px;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-bottom: 7px;
  font-family: 'Nunito Sans';
`;

const PersonPosition = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.13;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayText};
`;

interface Props {
  comment: string;
  image?: string;
  name: string;
  position: string;
  srcSet?: string;
}

const CustomerFeedbackItem: React.FC<Props> = ({
  comment,
  image,
  name,
  position,
  srcSet,
}) => {
  return (
    <CustomerFeedbackItemContainer>
      <div>
        <div>
          <Quote src="/img/quote.svg" />
        </div>
        <Comment>{comment}</Comment>
      </div>
      <PersonContainer>
        {image && (
          <div>
            <PersonImage src={image} srcSet={srcSet} />
          </div>
        )}
        <PersonInfoContainer className={classNames(!!image && 'with-image')}>
          <PersonName>{name}</PersonName>
          <PersonPosition>{position}</PersonPosition>
        </PersonInfoContainer>
      </PersonContainer>
    </CustomerFeedbackItemContainer>
  );
};

export default CustomerFeedbackItem;
