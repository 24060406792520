import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import CustomerFeedbackItem from './components/feedback-item';

const CustomerFeedbackContainer = styled.div`
  width: 100%;
  z-index: 1;
  ${media.min.lg`
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.max.md`
    padding-bottom: 50px;
    padding-left: 42px;
    padding-right: 42px;
  `}
  ${media.max.sm`
    padding-left: 34px;
    padding-right: 34px;
    padding-bottom: 40px;
  `}
   ${media.max.xs`
    padding-left: 20px;
    padding-right: 20px;
  `}
   ${media.max.xs`
   min-width: 250px;
  `}
`;

const CustomerFeedbackHeader = styled.h2`
  padding-top: 66px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors.grayExtraDark};
  width: 100%;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  ${media.max.md`
  padding-top:40px;
  `}
  ${media.max.sm`
  padding-top:20px;
  `}
`;

const CustomerFeedbackItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 38px;
  ${media.max.md`
  margin-top: 26px;

  `}
  ${media.max.sm`
  margin-top: 14px;

  `}
`;

const CustomersFeedback: React.FC = () => {
  return (
    <CustomerFeedbackContainer>
      <CustomerFeedbackHeader>
        What do our happy customers say about us?
      </CustomerFeedbackHeader>
      <CustomerFeedbackItemContainer>
        <CustomerFeedbackItem
          position={'International Director, Laboratorios Rubio'}
          name={'Albert Vich'}
          image={'/img/customers/albert.jpg'}
          srcSet={
            '/img/customers/albert@2x.jpg 2x, /img/customers/albert@3x.jpg 3x'
          }
          comment={`"We asked Pipelinepharma to find a customer in Mexico. It was amazing, they made it per 4 weeks and we signed the contract! I totally recommend using their networking and service."`}
        />
        <CustomerFeedbackItem
          position={'Business Development Director, Global Licensing Genepharm'}
          name={'Akis Chondrogiannis'}
          image={'/img/customers/akis.jpg'}
          srcSet={
            '/img/customers/akis@2x.jpg 2x, /img/customers/akis@3x.jpg 3x'
          }
          comment={`"By using Pipelinepharma service we found new reliable pharmaceutical companies with whom we signed licensing and supply agreements. Pipelinepharma has become an extension to our global sales channel for our company."`}
        />
      </CustomerFeedbackItemContainer>
    </CustomerFeedbackContainer>
  );
};

export default CustomersFeedback;
