import { ReactGA } from 'client/tracker';
import { anonymousUserId, routes } from 'app/config';
import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import { IOwnProps } from './';
import { useSelector } from 'app/helpers/redux';
import isBrowser from 'app/store/utils/is-browser';
import { AnchorLink } from 'app/components/links';

export const FAQForBuyersButtonStyle = styled.div`
  width: 230px;
  height: 50px;
  border-radius: 3px;
  border: solid 2px ${(props) => props.theme.colors.accent};
  font-size: 18px;
  font-weight: 600;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.accent};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito Sans';
  cursor: pointer;

  :hover {
    background-color: #fff5e5;
  }

  ${media.max.sm`
    width: 100%;
  `}
`;

const Wrapper = styled.div<{ widthFull?: boolean }>`
  display: flex;
  justify-content: center;
  ${media.max.sm`
  width: 100%;`}
  ${(props) => props.widthFull && ' & > a {width: 100%;}'}
`;

const FAQForBuyersButton: React.FC<IOwnProps> = ({
  category,
  action,
  label,
  isMarketplace,
  buyerRoute,
  isRightAssetBanner,
  fullWidth,
}) => {
  let link = '';
  if (isMarketplace) {
    if (buyerRoute) {
      link = routes.faqforbuyers;
    } else {
      link = routes.faqforsellers;
    }
  } else {
    if (buyerRoute) {
      if (isRightAssetBanner) {
        link = routes.companies_home_buyer;
      } else {
        link = routes.faqforacquisitionsBuyers;
      }
    } else {
      if (isRightAssetBanner) {
        link = routes.companies_home_seller;
      } else {
        link = routes.faqforacquisitionsSellers;
      }
    }
  }
  const email = useSelector((state) => state.auth?.loggedInUser?.email);
  const anonymousUserIdItem =
    isBrowser() && localStorage.getItem(anonymousUserId);
  return (
    <Wrapper widthFull={fullWidth}>
      <AnchorLink href={link} style={{ width: '100%' }}>
        <FAQForBuyersButtonStyle
          onClick={() => {
            if (category && action) {
              ReactGA.event({
                category,
                action,
                email,
                anonymousUserId: anonymousUserIdItem,
              });
            }
          }}
        >
          {label}
        </FAQForBuyersButtonStyle>
      </AnchorLink>
    </Wrapper>
  );
};
export default FAQForBuyersButton;
