import { IWithApolloClient } from 'app/store/modules/global/types';
import { createSubscribe } from 'app/store/modules/subscribe/actions';
import { Notifications } from 'app/store/modules/subscribe/types';
import { IStore } from 'app/store/types';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import SubscribeInput from './subscribe-input';

interface IStateProps {
  error: Notifications;
}
interface IDispatchProps {
  onSubmit: (email: string) => any;
}

export interface IProps extends IStateProps, IDispatchProps {}
export default withApollo<any>(
  connect<IStateProps, IDispatchProps, IWithApolloClient, IStore>(
    (state: IStore): IStateProps => {
      return {
        error: state.subscribe.error,
      };
    },
    (dispatch, ownProps) => {
      return {
        onSubmit: (email: string) => {
          dispatch(createSubscribe({ email, client: ownProps.client }));
        },
      };
    }
  )(SubscribeInput)
);
