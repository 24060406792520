import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import WhyItem from './components/why-item-bigger';

const WhyContainer = styled.div`
  ${media.min.lg`
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.max.md`
    padding-bottom: 50px;
    padding-left: 42px;
    padding-right: 42px;
  `}
  ${media.max.sm`
    padding-left: 34px;
    padding-right: 34px;
    padding-bottom: 40px;
  `}
   ${media.max.xs`
    min-width: 250px;
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

const WhyHeader = styled.h2`
  padding-top: 108px;
  height: 24px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  margin: 0;
  color: ${(props) => props.theme.colors.grayExtraDark};
  ${media.max.md`
  padding-top:80px;
  `}
  ${media.max.sm`
  padding-top:60px;

  `}
`;

const WhyItemContainer = styled.div`
  display: block;
`;

const WhyPipelinepharma: React.FC = () => {
  try {
    const { hash } = window.location;
    if (hash !== '') {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  } catch (e) {
    //
  }

  return (
    <div>
      <WhyContainer>
        <WhyHeader>Why Pipelinepharma?</WhyHeader>
        <WhyItemContainer>
          <WhyItem
            title={'Find what you want'}
            header={'Industry-specific search engine'}
            text={
              'Quickly find products by generic name and also using industry-specific filters including dossier type and status, GMP approvals, country of origin and many more.'
            }
            mirror={false}
            imageSrc={'/img/industry-specific.png'}
            srcSet={
              '/img/industry-specific@2x.png 2x, img/industry-specific@3x.png 3x'
            }
            width={'588px'}
            sellers={false}
          />
          <WhyItem
            title={'Products with prices'}
            header={'Competitive qualified products'}
            text={
              'Accept offer and either sign contract with the manufacturer or place an order for the first shipment. If you need better terms negotiate online - make your counter-proposal.'
            }
            mirror={true}
            imageSrc={'/img/competitive-qualified.png'}
            srcSet={
              '/img/competitive-qualified@2x.png 2x, img/competitive-qualified@3x.png 3x'
            }
            width={'565px'}
            sellers={false}
          />
          <WhyItem
            title={'Products without prices'}
            header={'Request for quotation'}
            text={
              'If you cannot find price for the product, market or deal type of your interest you can make a request online and we will try to find a match.'
            }
            mirror={false}
            imageSrc={'/img/request-quotation.png'}
            srcSet={
              '/img/request-quotation@2x.png 2x, img/request-quotation@3x.png 3x'
            }
            width={'555px'}
            sellers={false}
          />
          <WhyItem
            title={'Sign'}
            header={'Sign contract or make order'}
            text={
              'Sign contract with the manufacturer and initiate registration process in your country. Or place an order to the distributor to get your shipment as soon as all permits are in place.'
            }
            mirror={true}
            imageSrc={'/img/contract-types.png'}
            srcSet={
              '/img/contract-types@2x.png 2x, img/contract-types@3x.png 3x'
            }
            width={'555px'}
            sellers={false}
          />
        </WhyItemContainer>
      </WhyContainer>
    </div>
  );
};

export default WhyPipelinepharma;
