import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

const LogoContainer = styled.div`
  width: 100%;
  z-index: 1;
  ${media.min.lg`
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.max.md`
    padding-bottom: 50px;
    padding-left: 42px;
    padding-right: 42px;
  `}
  ${media.max.sm`
    padding-left: 34px;
    padding-right: 34px;
    padding-bottom: 40px;
  `}
   ${media.max.xs`
    padding-left: 20px;
    padding-right: 20px;
  `}
   ${media.max.xs`
   min-width: 250px;
  `}
`;
const LogoLineContainer = styled.div`
  width: 100%;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;

  ${media.max.md`
    justify-content: center;
    -webkit-justify-content: center;
  `};
  ${media.max.tablet`
    justify-content: space-between;  
    -webkit-justify-content: space-between;
  `};
  ${media.max.sm`
    justify-content: space-between;  
    -webkit-justify-content: space-between;
  `};
`;

export const LogoLineImageContainer = styled.div`
  /* flex: 1; */
  padding: 5px;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;

  ${media.max.md`
  width: 20%;
  min-width: 190px;
  `};
  ${media.max.sm`
  > img{
    object-fit: contain;
    max-width: 100%;
  }
  margin-bottom: 50px;
  width: 50%;
  min-width: 130px;
  `}
`;

const LogoHeader = styled.h2`
  width: 100%;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-top: 100px;
  margin-bottom: 0;
  padding-bottom: 40px;
  ${media.max.md`
    margin-top: 67px;
  `}
  ${media.max.sm`
    margin-top: 20px;
  `}
 ${media.max.xs`
    text-align:left
  `}
`;

const renderLogo = (img: string, srcSet: string, altTag?: string) => {
  return (
    <LogoLineImageContainer>
      <img src={img} srcSet={srcSet} alt={altTag} />
    </LogoLineImageContainer>
  );
};

const LogoLine: React.FC = () => {
  return (
    <LogoContainer className="__logo-container">
      <LogoHeader> Trusted by over 400 businesses around the world</LogoHeader>
      <LogoLineContainer>
        {renderLogo(
          '/img/customer-logo/macleods.png',
          '/img/customer-logo/macleods@2x.png 2x, img/customer-logo/macleods@3x.png 3x',
          'Macleods Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/medochemie.png',
          '/img/customer-logo/medochemie@2x.png 2x, img/customer-logo/medochemie@3x.png 3x',
          'Medochemie Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/genepharm.png',
          '/img/customer-logo/genepharm@2x.png 2x, img/customer-logo/genepharm@3x.png 3x',
          'Genepharm Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/arasto.png',
          '/img/customer-logo/arasto@2x.png 2x, img/customer-logo/arasto@3x.png 3x',
          'Aristo Pharma Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/biofarm.png',
          '/img/customer-logo/biofarm@2x.png 2x, img/customer-logo/biofarm@3x.png 3x',
          'BioPharm Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/glenmark.png',
          '/img/customer-logo/glenmark@2x.png 2x, img/customer-logo/glenmark@3x.png 3x',
          'Glenmark Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/pharmanel.jpg',
          '/img/customer-logo/pharmanel@2x.jpg 2x, img/customer-logo/pharmanel@3x.jpg 3x',
          'Pharmanel Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/suanfarma.png',
          '/img/customer-logo/suanfarma@2x.png 2x, img/customer-logo/suanfarma@3x.png 3x',
          'Suanfarma Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/gap.png',
          '/img/customer-logo/gap@2x.png 2x, img/customer-logo/gap@3x.png 3x',
          'Global Allied Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/indsudpharma.png',
          '/img/customer-logo/indsudpharma@2x.png 2x, img/customer-logo/indsudpharma@3x.png 3x',
          'Insud Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/alkaloid.jpg',
          '/img/customer-logo/alkaloid@2x.jpg 2x, img/customer-logo/alkaloid@3x.jpg 3x',
          'Alkaloid AD Skopje Pharmaceuticals'
        )}
        {renderLogo(
          '/img/customer-logo/refarm.png',
          '/img/customer-logo/refarm@2x.png 2x, img/customer-logo/refarm@3x.png 3x',
          'Rafarm SA Pharmaceuticals'
        )}
      </LogoLineContainer>
    </LogoContainer>
  );
};

export default LogoLine;
