import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import SubscribeInput from './components/subscribe-input';

const SubscribeContainer = styled.div`
  height: 350px;
  width: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(254deg, #3678be, #1299dd);
  ${media.max.md`
  height: 300px;
  `}
  ${media.max.sm`
  height: 300px;
  `}
`;

const FullWidthAndCenter = styled.div`
  width: 100%;
  align-items: center;
  ${media.min.lg`
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
`}
`;

const SingUpHeader = styled.h1`
  font-size: 36px;
  line-height: 40px;
  line-height: 1.11;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  margin-top: 0;
  margin-bottom: 26px;
  padding-left: 25%;
  padding-right: 25%;
  font-weight: normal;

  ${media.max.md`
  font-size: 30px;
  margin-bottom: 20px;
  padding-left: 20%;
  padding-right: 20%;
  `}
  ${media.max.sm`
  font-size: 22px;
  padding-bottom: 20px;
  padding-left: 10%;
  padding-right: 10%;
margin-bottom: 0px;
  `}
`;

const SubscribeInputContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  ${media.max.md`
  width: 60%;

  `}
  ${media.max.sm`
  width: 90%;

  `}
`;

const Subscribe: React.SFC = () => {
  return (
    <SubscribeContainer>
      <FullWidthAndCenter>
        <SingUpHeader>
          {`Sign up and we'll send you the best requests and industry news to you!`}
        </SingUpHeader>
        <SubscribeInputContainer>
          <SubscribeInput />
        </SubscribeInputContainer>
      </FullWidthAndCenter>
    </SubscribeContainer>
  );
};

export default Subscribe;
