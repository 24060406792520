import { routes } from 'app/config';
import { media } from 'app/styles/breakpoint';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import FAQItem from './components/faq-item';

const FAQContainer = styled.div`
  width: 100%;
  background-color: ${(props) => props.theme.colors.white};
  ${media.min.lg`
    max-width: 1180px;
    margin-left: auto;
    margin-right: auto;
  `}

  ${media.max.md`
    padding-bottom: 50px;
    padding-left: 42px;
    padding-right: 42px;
  `}
  ${media.max.sm`
    padding-left: 34px;
    padding-right: 34px;
    padding-bottom: 40px;
  `}
   ${media.max.xs`
    min-width: 250px;
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

const FAQHeader = styled.h2`
  padding-top: 100px;
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  margin-top: 0;
  margin-bottom: 0;
  ${media.max.md`
  padding-top:40px;

  `}
  ${media.max.sm`
  padding-top:0px;
  `}
`;

const FAQFooter = styled.div`
  margin-top: 30px;

  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.69;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayText};
  padding-bottom: 68px;
  ${media.max.md`
  padding-bottom: 60px;

  `}
  ${media.max.sm`
  margin-top:10px;
  padding-bottom: 30px;
  `}
`;

const FAQItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 38px;
  ${media.max.md`
margin-top: 26px;

  `}
  ${media.max.sm`
  margin-top: 22px;

  `}
`;

const FAQLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  color: ${(props) => props.theme.colors.primary};
  > a {
    color: ${(props) => props.theme.colors.primary};
  }
`;
interface IOwnProps {
  isSeller?: boolean;
}

const FAQ: React.FC<IOwnProps> = ({ isSeller }) => {
  return (
    <FAQContainer>
      <FAQHeader>FAQs</FAQHeader>
      <FAQItemContainer>
        <FAQItem
          header={
            'How can I be sure about the quality of the dossier and the manufacturer?'
          }
          text={
            'We have a standard industry-specific pre-auditing questionnaire where the manufacturers provide all the details about the dossier. Regulatory and commercial information is shared with you on the product card. However it is your responsibility to make the dossier audit and register the product in your market. '
          }
        />
        <FAQItem
          header={
            'Why should I provide my company data, annual purchase forecast, and market data?'
          }
          text={
            'Company data is important for the manufacturer to confirm your candidacy for a potential long-term partnership. The annual purchase forecast allows the manufacturer to estimate the business case size and compare with their minimum order quantities. The market price level gives the manufacturer an opportunity to evaluate their feasibility of providing a competitive offer.'
          }
        />
      </FAQItemContainer>
      <FAQFooter>
        Still not sure about something? Find answers to all your questions on
        our{' '}
        <FAQLink>
          <Link to={isSeller ? routes.faqforsellers : routes.faqforbuyers}>
            FAQ&apos;s
          </Link>{' '}
        </FAQLink>
      </FAQFooter>
    </FAQContainer>
  );
};

export default FAQ;
