import { push } from 'connected-react-router';
import { withApollo } from 'react-apollo';
import { connect } from 'react-redux';

// custom imports
import { routes } from 'app/config';
import { toTitleCase } from 'app/store/modules/global/actions';
import { IWithApolloClient } from 'app/store/modules/global/types';
import {
  setCurrentPage,
  setIsFetching,
  setMarketplaceFilter,
} from 'app/store/modules/marketplace/actions';
import {
  AutocompleteProductType,
  MarketplaceFilter,
} from 'app/store/modules/marketplace/types';
import {
  fetchProductsSelect,
  filterProductsSelect,
} from 'app/store/modules/products/query';
import { IStore } from 'app/store/types';
import { sg } from 'app/utils/safe-get';
import { Components } from './search-input';
import SearchInput from './search-input';

interface IStateProps {
  filter: MarketplaceFilter;
  urlQuery?: string;
  isElastic?: boolean;
}

export interface IBrandSelect extends ISelectItem {
  type: number;
}

// type 1 licensing
// type 2 brand
interface IDispatchProps {
  setFilter: (filter: MarketplaceFilter, urlQuery?: string) => any;
  fetchSubstance: (
    name: string,
    callback: (options: IBrandSelect[]) => any
  ) => any;
}
interface IOwnProps {
  isMarketplace?: boolean;
  keyword?: string;
  pageFromUrl?: number;
  suggestionsCount?: number;
  isNavbar?: boolean;
}

export interface IProps extends IStateProps, IDispatchProps, IOwnProps {}
export default withApollo<IOwnProps>(
  connect<IStateProps, IDispatchProps, IWithApolloClient<IOwnProps>, IStore>(
    (state) => ({
      filter: state.marketplace.filter,
      urlQuery: state.router.location.search,
      isElastic: sg(() => state.marketplace.isElastic, false),
    }),
    (dispatch, ownProps) => ({
      setFilter: (filter, urlQuery) => {
        dispatch(setIsFetching(true));
        dispatch(setCurrentPage(ownProps.pageFromUrl || 0));
        dispatch(setMarketplaceFilter(filter));
        if (filter.product !== '') {
          const productWithoutSlashes = filter.product
            .replace('/', ' ')
            .replace(/ +(?= )/g, '');
          if (ownProps.keyword !== filter.product) {
            dispatch(
              push(
                `/${productWithoutSlashes
                  .toLowerCase()
                  .replace(/ /g, '-')}-manufacturers${urlQuery}`
              )
            );
          }
        } else {
          dispatch(push(`${routes.marketplace}${urlQuery}`));
        }
        window.scrollTo(0, 0);
      },
      fetchSubstance: async (name, callback) => {
        const res =
          !name || name.length === 0
            ? await fetchProductsSelect({
                client: ownProps.client,
                type: AutocompleteProductType.Substance,
              })
            : await filterProductsSelect({
                client: ownProps.client,
                text: name,
                type: AutocompleteProductType.Substance,
              });
        if (!res) {
          return;
        }
        const filteredOptions = res.data.options.filter(
          (option: IBrandSelect) => !option.label.includes('%')
        );
        const options = filteredOptions.map((x: IBrandSelect) => ({
          ...x,
          label: toTitleCase(x.label),
        }));
        callback(options);
        return options;
      },
    })
  )(SearchInput)
);

export { Components };
