import { GAActions, GACategory } from 'client/tracker';
import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import FAQForBuyersButton from '../faq-for-buyers-button';
import MarketplaceButton from '../marketplace-button';

const ButtonContainer = styled.div`
  width: 100%;
  padding-left: 10%;
  padding-right: 10%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  min-height: 180px;
  background-color: ${(props) => props.theme.colors.background};
  padding-top: 68px;
  padding-bottom: 50px;
  ${media.min.tablet`
  div:first-child {
    margin-right: 10px;
  }
  div:last-child {
    margin-left: 10px;
  }
`}
  ${media.max.md`
  padding-top: 57px;
  padding-bottom: 42px;


  `}
  ${media.max.sm`
  padding-left: 5%;
  padding-right: 5%;
padding-top: 46px;
padding-bottom: 35px;
 div {
  margin-bottom: 11px;
  margin-right: 0px;
}
  `}
`;
interface Props {
  category?: string;
}
const ButtonLine: React.SFC<Props> = ({ category }) => {
  const isBuyerPage =
    GACategory &&
    (category === GACategory.FAQForSellersPage ||
      category === GACategory.FAQForAquisitionsSellers);
  const isMarketplace =
    GACategory &&
    (category === GACategory.FAQForBuyersPage ||
      category === GACategory.FAQForSellersPage);
  return (
    <ButtonContainer>
      <MarketplaceButton
        category={category}
        action={GAActions ? GAActions.goToMarketplaceBottom : undefined}
        fullWidth={true}
      />
      {isBuyerPage ? (
        <FAQForBuyersButton
          category={category}
          action={GAActions ? GAActions.goToFAQBottom : undefined}
          label={'FAQ’s for sellers'}
          buyerRoute={false}
          isMarketplace={isMarketplace}
          fullWidth={true}
        />
      ) : (
        <FAQForBuyersButton
          category={category}
          action={GAActions ? GAActions.goToFAQBottom : undefined}
          label={'FAQ’s for buyers'}
          buyerRoute={true}
          isMarketplace={isMarketplace}
          fullWidth={true}
        />
      )}
    </ButtonContainer>
  );
};

export default ButtonLine;
