import FAQForBuyersButton from './faq-for-buyers-button';

export interface IOwnProps {
  buyerRoute: boolean;
  category?: string;
  action?: string;
  label: string;
  isMarketplace?: boolean;
  isRightAssetBanner?: boolean;
  fullWidth?: boolean;
}
export default FAQForBuyersButton;
