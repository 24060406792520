import { GAActions, GACategory, ReactGA } from 'client/tracker';
import TermOfServiceModal from 'app/modules/landings/terms-of-service-modal';
import { media } from 'app/styles/breakpoint';
import React, { useState } from 'react';
import styled from 'styled-components';
import { IProps } from './index';
import { useSelector } from 'app/helpers/redux';
import { anonymousUserId } from 'app/config';
import isBrowser from 'app/store/utils/is-browser';

const SubscribeInputContainer = styled.div`
  width: 100%;
  height: 56px;
  border-radius: 3px;
  border: solid 1px ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  ${media.max.md`
  height: 55px;
  `}
  ${media.max.sm`
  height: 50px;
  `}
`;
const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;
const InputWrapper = styled.div`
  flex: 3;
  display: flex;
`;

const TOS = styled.span`
  cursor: pointer;
  text-decoration: underline;
`;

export const TextInput = styled.input`
  padding-top: 18px;
  padding-bottom: 18px;
  padding-left: 30px;

  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.white};
  background-color: transparent;
  border: 0px;
  width: 100%;

  ::placeholder {
    color: ${(props) => props.theme.colors.white};
    opacity: 1;
  }

  ${media.max.md`
  font-size: 18px;
  `}
  ${media.max.sm`
  font-size: 14px;
  `}
`;

export const SubscribeButton = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 56px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.white};
  max-width: 150px;
  font-size: 18px;
  font-weight: 600;
  line-height: 14px;
  letter-spacing: -0.4px;
  color: ${(props) => props.theme.colors.primary};
  text-align: center;
  font-family: 'Nunito Sans';
  border: 0px;
  cursor: pointer;
  ${media.max.md`
  font-size: 18px;
  height: 55px;

  `}
  ${media.max.sm`
  height: 50px;
  font-size: 16px;
  padding: 16px;
  `}
`;

export const SubscribeApprove = styled.div`
  font-size: 14px;
  line-height: 26px;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  margin-top: 17px;
  justify-content: center;
  cursor: pointer;
  ${media.max.md`
  margin-top: 15px;
  justify-content: flex-start;

  `}
  ${media.max.sm`
  margin-top: 15px;

  `}
`;

const SubscribeCheckbox = styled.div`
  margin-right: 15px;
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
  background-color: transparent;
  padding: 2;
  border-radius: 2px;
  box-shadow: 0 0 8px 0 rgba(177, 177, 177, 0.5);
  border: solid 1px ${(props) => props.theme.colors.white};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CheckStyles = styled.img`
  width: 12px;
  height: 9.5px;
  object-fit: contain;
`;

export const ErrorBubble = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  top: -60px;
  display: flex;
  justify-content: center;
  border-radius: 3px;
`;

export const SuccessBubble = styled.div`
  position: absolute;
  border-radius: 3px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 44px;
  top: -60px;
  ${media.max.xs`
  top: -77px;

  `}
  display: flex;
  justify-content: center;
`;

const ErrorBubbleWrapper = styled.div`
  position: relative;
  display: flex;
`;

const ErrorBubleIconContainer = styled.div`
  background-color: #fae1e1;
  width: 44px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -60px;

  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 22px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #fae1e1;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
`;

const SuccessBubleIconContainer = styled.div`
  background-color: #e6fad0;
  width: 44px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -60px;

  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  ${media.max.xs`
  top: -77px;

  `}
  :after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 22px;
    width: 0;
    height: 0;
    border: 10px solid transparent;
    border-top-color: #e6fad0;
    border-bottom: 0;
    margin-left: -10px;
    margin-bottom: -10px;
  }
`;

const ErrorBubleTextContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  text-align: center;
  min-height: 44px;
  width: fit-content;
  padding: 15px;

  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
`;
export const ErrorBubleText = styled.div`
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const ErrorIcon = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;

export const renderBubbleError = (error: string) => {
  if (error !== '') {
    return (
      <ErrorBubble id={'error-bubble'}>
        <ErrorBubbleWrapper>
          <ErrorBubleIconContainer>
            <ErrorIcon src={'/img/error-icon.svg'} />
          </ErrorBubleIconContainer>
          <ErrorBubleTextContainer>
            <ErrorBubleText>{error}</ErrorBubleText>
          </ErrorBubleTextContainer>
        </ErrorBubbleWrapper>
      </ErrorBubble>
    );
  } else {
    return <div />;
  }
};

export const renderBubbleSuccess = (message: string) => {
  if (message !== '') {
    return (
      <SuccessBubble id={'error-bubble'}>
        <ErrorBubbleWrapper>
          <SuccessBubleIconContainer>
            <ErrorIcon src={'/img/success-small.svg'} />
          </SuccessBubleIconContainer>
          <ErrorBubleTextContainer>
            <ErrorBubleText>{message}</ErrorBubleText>
          </ErrorBubleTextContainer>
        </ErrorBubbleWrapper>
      </SuccessBubble>
    );
  } else {
    return <div />;
  }
};

export const emailErrorMsg = 'Enter valid email address';
export const agreeErrorMsg =
  'You must agree with Terms of Use and Privacy Policy';

const SubscribeInput: React.FC<IProps> = ({ onSubmit, error }) => {
  const [agree, setAgree] = useState(false);
  const [text, setText] = useState('');
  const [emailError, setEmailError] = useState('');
  const [success, setSuccess] = useState('');
  const [agreeToRulesError, setAgreeToRulesError] = useState('');
  const validateEmail = (email: string) => {
    // eslint-disable-next-line
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };
  const email = useSelector((state) => state.auth?.loggedInUser?.email);
  const anonymousUserIdItem =
    isBrowser() && localStorage.getItem(anonymousUserId);

  const onFinish = (event: any) => {
    event.preventDefault();
    if (!validateEmail(text)) {
      setEmailError(emailErrorMsg);
      return;
    } else {
      setEmailError('');
      setAgreeToRulesError('');
    }

    if (!agree) {
      setAgreeToRulesError(agreeErrorMsg);
      return;
    } else {
      setAgreeToRulesError('');
    }

    if (error.listError.length > 0) {
      return;
    }
    ReactGA.event({
      category: GACategory ? GACategory.Landing : '',
      action: GAActions ? GAActions.subscribe + ': ' + text : '',
      email,
      anonymousUserId: anonymousUserIdItem,
    });
    onSubmit(text);
    setSuccess(
      'Your subscription to the Pipelinepharma newsletter is confirmed!'
    );
    setText('');
  };

  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Wrapper>
      <TermOfServiceModal
        isOpen={modalOpen}
        closeModal={() => {
          setModalOpen(false);
        }}
      />
      <form id="subscribe-form" role="form" onSubmit={onFinish}>
        {renderBubbleError(emailError)}
        {renderBubbleError(agreeToRulesError)}
        {renderBubbleSuccess(success)}
        {error.listError.length > 0 && renderBubbleError(error.listError)}
        <SubscribeInputContainer>
          <InputWrapper>
            <TextInput
              type="text"
              value={text}
              placeholder={'Enter your email'}
              onChange={(ev) => {
                setText(ev.target.value);
                if (validateEmail(ev.target.value)) {
                  setEmailError('');
                }
              }}
            />
          </InputWrapper>
          <SubscribeButton type="submit" value={'Subscribe'} />
        </SubscribeInputContainer>
        <SubscribeApprove
          onClick={() => {
            setAgree(!agree);
            if (!agree) {
              setAgreeToRulesError('');
            }
          }}
        >
          <SubscribeCheckbox>
            {agree && <CheckStyles src="img/check.svg" />}
          </SubscribeCheckbox>
          <div>
            I agree to Pipelinepharma’s{' '}
            <TOS
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setModalOpen(true);
              }}
            >
              Terms of Use and Privacy Policy
            </TOS>
          </div>
        </SubscribeApprove>
      </form>
    </Wrapper>
  );
};

export default SubscribeInput;
