import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';

const FAQItemContainer = styled.div`
  flex: 1;
  min-width: 350px;
  max-width: 100%;
  :first-child {
    padding-right: 30px;
  }

  padding-bottom: 30px;

  ${media.max.sm`
  :first-child {
    padding-right: 0px;
  }
  margin-right: 0px;

  `}
  ${media.max.xs`
  min-width: 250px;
  max-width: 100%;

  `}
`;

const FAQHeader = styled.h1`
  font-size: 30px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  min-height: 135px;
  ${media.max.md`
  min-height: 108px;
`}
  ${media.max.tablet`
  min-height: 80px;
`}
  margin: 0;
`;

const FAQText = styled.p`
  margin-top: 45px;

  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.63;
  letter-spacing: normal;
  margin: 0;
  color: ${(props) => props.theme.colors.grayExtraDark};
  ${media.max.md`
  margin-top: 46px;
  `}
  ${media.max.tablet`
  margin-top: 30px;
`}
  ${media.max.sm`
  margin-top: 18px;

  `}
`;

interface Props {
  header: string;
  text: string;
}

const FAQItem: React.FC<Props> = ({ header, text }) => {
  return (
    <FAQItemContainer>
      <FAQHeader>{header}</FAQHeader>
      <FAQText>{text}</FAQText>
    </FAQItemContainer>
  );
};

export default FAQItem;
