import React from 'react';
import styled from 'styled-components';

// custom imports
import { media } from 'app/styles/breakpoint';
import SearchInput from './components/search-input';

const SearchContainer = styled.div<{ isMarketplace: boolean }>`
  min-height: ${(props) => (props.isMarketplace ? '180px' : '449px')};
  width: 100%;
  background-image: url('/img/group-16.20200205.svg');
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  ${media.max.md`
    min-height: ${(props: any) => (props.isMarketplace ? '200px' : '350px')}
  padding-bottom: 30px;
  padding-top: 20px;
  `}

  ${media.max.sm`
    min-height: ${(props: any) =>
      props.isMarketplace ? 'fit-content' : '200px'}

  `}
`;

const FullWidthAndCenter = styled.div`
  width: 100%;
  align-items: center;
  ${media.min.lg`
  max-width: 1180px;
  margin-left: auto;
  margin-right: auto;
`}
`;

const CompetitiveLicensing = styled.h1`
  font-size: 38px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.11;
  letter-spacing: normal;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  margin-top: 0;
  margin-bottom: 26px;

  ${media.max.md`
    font-size: 30px;
    margin-bottom: 20px;
    padding-left: 10%;
  padding-right: 10%;
  `}

  ${media.max.sm`
    font-size: 22px;
    padding-top:17px;
    padding-left: 10%;
    padding-right: 10%;
  `}

${media.max.xs`
      margin-bottom: 0px;

  `}
`;

const SearchInputContainer = styled.div`
  width: 70%;
  margin: 0 auto;

  ${media.max.md`
    width: 75%;
  `}
  ${media.max.sm`
    width: 85%;
  `}
  ${media.max.xs`
    width: 90%;
    padding-top: 5%;
    padding-bottom: 5%;
  `}
`;

interface Props {
  isMarketplace?: boolean;
  keyword?: string;
  pageFromUrl?: number;
}

const Search: React.FC<Props> = ({
  isMarketplace = false,
  keyword,
  pageFromUrl,
}) => {
  return (
    <SearchContainer
      isMarketplace={isMarketplace ? true : false}
      id={'search-container'}
    >
      <FullWidthAndCenter>
        {!isMarketplace && (
          <CompetitiveLicensing>
            Pharmaceutical products for licensing and distribution
          </CompetitiveLicensing>
        )}
        <SearchInputContainer>
          <SearchInput
            isMarketplace={isMarketplace}
            keyword={keyword}
            pageFromUrl={pageFromUrl}
          />
        </SearchInputContainer>
      </FullWidthAndCenter>
    </SearchContainer>
  );
};

export default Search;
