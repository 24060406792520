import { routes } from 'app/config';
import { IStore } from 'app/store/types';
import { push } from 'connected-react-router';
import { connect } from 'react-redux';
import Description from './description';

interface IOwnProps {
  title: string;
  text: string;
  header: string;
  mirror: boolean;
  sellers: boolean;
  center: boolean;
  isButton: boolean;
  customLink?: string | (() => any);
}
interface IStateProps {
  isLoggedIn: boolean;
  currentPath: string;
}
interface IDispatchProps {
  onClick: () => any;
  onClickAlternative: () => any;
}
export interface IProps extends IStateProps, IDispatchProps, IOwnProps {}
export default connect<IStateProps, IDispatchProps, IOwnProps, IStore>(
  (state) => {
    return {
      isLoggedIn: state.auth.loggedIn,
      currentPath: state.router.location.pathname,
    };
  },
  (dispatch, ownProps) => {
    return {
      onClick: () => {
        try {
          if (ownProps.customLink) {
            if (typeof ownProps.customLink === 'string') {
              return dispatch(push(ownProps.customLink));
            } else if (ownProps.customLink instanceof Function) {
              return dispatch(ownProps.customLink());
            }
          }
          dispatch(push(routes.products));
        } catch (error) {
          // fail silently, no harm done
          console.log(error);
        }
      },
      onClickAlternative: () => {
        try {
          if (ownProps.customLink) {
            if (typeof ownProps.customLink === 'string') {
              return dispatch(push(ownProps.customLink));
            } else if (ownProps.customLink instanceof Function) {
              return dispatch(ownProps.customLink());
            }
          }
          window.scrollTo(0, 0);
        } catch (error) {
          // fail silently, no harm done
          console.log(error);
        }
      },
    };
  }
)(Description);
