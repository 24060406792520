import { GAActions, GACategory } from 'client/tracker';
import MarketplaceButton from 'app/components/buttons/marketplace-button';
import { routes } from 'app/config';
import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import { IProps } from './index';

const ItemHeader = styled.h4<{ mirror: boolean; center: boolean }>`
  color: ${(props) => props.theme.colors.accent};
  font-style: normal;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 10px;
  max-width: 376px;
  margin: 0;
  ${(props) => (props.mirror && !props.center ? 'margin-left: auto;' : '')}
  ${(props) => (props.center ? 'margin: auto;' : '')}
  ${media.max.tablet`
  max-width: 280px;
  `}
  ${media.max.sm`
    margin-left: 0;
  max-width: 100%;
text-align:left;
  `}
`;
const ItemHederSecond = styled.h1<{ mirror: boolean; center: boolean }>`
  font-size: 30px;
  padding-bottom: 6px;
  max-width: 376px;
  font-weight: normal;
  color: ${(props) => props.theme.colors.grayExtraDark};
  ${(props) => (props.mirror && !props.center ? 'margin-left: auto;' : '')}
  ${(props) => (props.center ? 'margin: auto;' : '')}
  ${media.max.tablet`
  max-width: 280px;
  `}
  ${media.max.sm`
    font-size: 28px;
    max-width: 100%;
text-align:left;
  `}
`;
const ItemDescription = styled.div<{ mirror: boolean; center: boolean }>`
  font-size: 18px;
  line-height: 28px;
  ${(props) => (props.mirror && !props.center ? 'margin-left: auto;' : '')}
  ${(props) =>
    props.center ? 'margin: auto; max-width: 700px;' : 'max-width: 376px;'}
 ${media.max.tablet`
 ${(props: any) =>
   props.center ? 'margin: auto; max-width: 700px;' : 'max-width: 280px;'}
  `}
  ${media.max.sm`
    font-size: 18px;
    max-width: 100%;
text-align:left;
  `}
  color: ${(props) => props.theme.colors.grayExtraDark};
`;

const TextP = styled.p`
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props) => props.theme.colors.grayExtraDark};
`;
const MarketplaceButtonContainer = styled.div`
  padding-top: 30px;
  width: 230px;
  ${media.max.md`
  padding-top: 20px;
  `}

  ${media.max.sm`
  padding-top: 30px;
  padding-bottom: 30px;
  `}
`;
const Wrapper = styled.div<{ center: boolean }>`
  ${(props) => (props.center ? 'text-align: center;' : '')}
`;
const SendButton = styled.div`
  width: 200px;
  height: 50px;
  border-radius: 3px;
  background-color: ${(props) => props.theme.colors.warning};
  font-size: 18px;
  font-weight: bold;
  line-height: 19px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${media.max.xs`
    width: 100%;
  `}
  :hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;
const Description: React.FC<IProps> = ({
  title,
  text,
  header,
  mirror,
  sellers,
  center,
  isButton,
  isLoggedIn,
  onClick,
  onClickAlternative,
  customLink,
  currentPath,
}) => {
  let gaCategory = GACategory ? GACategory.Landing : undefined;
  let gaAction = GAActions ? GAActions.goToMarketplaceCenter : undefined;

  if (currentPath === routes.companies_home_seller) {
    gaCategory = GACategory ? GACategory.MABuyerLanding : undefined;
    gaAction = GAActions ? GAActions.goToMarketplaceCenter : undefined;
  }

  if (currentPath === routes.sellers_home) {
    gaCategory = GACategory ? GACategory.SellerLanding : undefined;
    gaAction = GAActions ? GAActions.goToMarketplaceCenter : undefined;
  }

  return (
    <Wrapper center={center}>
      <ItemHeader mirror={mirror} center={center}>
        {title !== undefined && title}
      </ItemHeader>
      <ItemHederSecond mirror={mirror} center={center}>
        {header}
      </ItemHederSecond>
      <ItemDescription mirror={mirror} center={center}>
        <TextP>{text !== undefined && text}</TextP>
        {isButton ? (
          <MarketplaceButtonContainer>
            {!sellers ? (
              <MarketplaceButton
                category={gaCategory}
                action={gaAction}
                fullWidth={true}
                customLink={
                  customLink && typeof customLink === 'string'
                    ? customLink
                    : undefined
                }
              />
            ) : (
              <SendButton onClick={isLoggedIn ? onClick : onClickAlternative}>
                Start selling
              </SendButton>
            )}
          </MarketplaceButtonContainer>
        ) : (
          <div />
        )}
      </ItemDescription>
    </Wrapper>
  );
};

export default Description;
