import { media } from 'app/styles/breakpoint';
import React from 'react';
import styled from 'styled-components';
import Description from './description';

const ItemContainer = styled.div<{ mirror: boolean }>`
  display: flex;
  width: 100%;
  padding-top: 43px;
  padding-bottom: 86px;
  border-bottom: 1px solid ${(props) => props.theme.colors.grayDivider};
  ${(props) => (props.mirror ? 'flex-direction: row-reverse;' : '')}
  ${media.max.sm`
    display: block;
    padding-top: 43px;
    padding-bottom: 43px;
  `}
`;
const ItemContent = styled.div`
  flex: 1;
  display: block;
  margin: auto;
  ${media.max.md`
  max-width: 400px;
  `}

  ${media.max.tablet`
  max-width: 306px;
  `}
  ${media.max.sm`
  flex: none;
  width: 100%;
  margin: 0px;
  max-width: 100%;

`}
`;
const ItemIllustrations = styled.div<{
  width: string;
  mirror: boolean;
  hideBackground?: boolean;
}>`
  display: flex;
  justify-content: center;

  flex-direction: column;
  ${(props) => !props.mirror && `align-items:flex-end;`}

  width: 100%;
  height: auto;
  ${(props) =>
    !props.mirror ? 'margin-left: auto;  align-items: flex-end;' : ''}
  ${media.max.sm`
    margin: auto;
    align-items:center;
  `}
  ${media.max.xs`
  // height: 43vh;
  // height: 70vh;
  // height: 320px;
  `}
`;

const IllustrationWrapper = styled.div`
  flex: 1;
  position: relative;

  ${media.max.sm`
  flex: none;
  width: 100%;
`}
`;

const ItemPictureImage = styled.img`
  max-width: 100%;
  height: auto;
  object-fit: cover;
`;

interface Props {
  title: string;
  text: string;
  header: string;
  mirror: boolean;
  width: string;
  sellers: boolean;
  imageSrc: string;
  srcSet: string;
  height?: string;
  hideBackground?: boolean;
  customLink?: string | (() => any);
}

const WhyItem: React.FC<Props> = ({
  title,
  text,
  header,
  mirror,
  width,
  height,
  sellers,
  imageSrc,
  srcSet,
  hideBackground,
  customLink,
}) => {
  return (
    <ItemContainer mirror={mirror}>
      <ItemContent>
        <Description
          mirror={mirror}
          title={title}
          header={header}
          text={text}
          sellers={sellers}
          center={false}
          isButton={true}
          customLink={customLink}
        />
      </ItemContent>
      <IllustrationWrapper>
        <ItemIllustrations
          width={width}
          mirror={mirror}
          hideBackground={hideBackground}
        >
          {/* <ItemPicture photo={photoString} /> */}
          <ItemPictureImage width={width} src={imageSrc} srcSet={srcSet} />
        </ItemIllustrations>
      </IllustrationWrapper>
    </ItemContainer>
  );
};

export default WhyItem;
